import request from "../utils/request";

// 登录
export async function applyLogin(data = {}) {
  try {
    return await request({
      url: "/auth/login",
      method: "post",
      data,
    });
  } catch (error) {
    console.log(error);
    return {};
  }
}
//获取微信登陆注册二维码
export async function qrCode() {
  try {
    return await request({
      url: "/wechat/qrcode",
      method: "get",
    });
  } catch (e) {
    console.log(e);
    return {};
  }
}

//获取用户邀请列表
export async function invitationList(activeId) {
  try {
    return await request({
      url: "/user/getActiveParticipateDetailVO?activeId=" + activeId,
      method: "get",
    });
  } catch (e) {
    console.log(e);
    return {};
  }
}
//获取活动
export async function getAcitve(activeType) {
  try {
    return await request({
      url: "/active?activeType=" + activeType,
      method: "get",
    });
  } catch (e) {
    console.log(e);
    return {};
  }
}
//获取结束时间
export async function getEndTime() {
  try {
    return await request({
      url: "/active/groupBuy/getEndTime",
      method: "get",
    });
  } catch (e) {
    console.log(e);
    return {};
  }
}
//获取活动详情列表
export async function getActiveDetail(activeid) {
  try {
    return await request({
      url: "/active/groupBuy/getComboList/" + activeid,
      method: "get",
    });
  } catch (e) {
    console.log(e);
    return {};
  }
}
//获取活动详情列表
export async function selfGroupBuyList(data) {
  try {
    return await request({
      url: "/active/groupBuy/selfGroupBuyList",
      method: "post",
      data,
    });
  } catch (e) {
    console.log(e);
    return {};
  }
}

//获取用户邀请链接
export async function invitationLink() {
  try {
    return await request({
      url: "/user/getInvitationUrl",
      method: "get",
    });
  } catch (e) {
    console.log(e);
    return {};
  }
}
//获取微信登录注册二维码扫描结果
export async function checkScene(ticket, inviteCode) {
  try {
    return await request({
      url: `/wechat/check/${ticket}?inviteCode=${inviteCode}`,
      method: "get",
    });
  } catch (e) {
    console.log(e);
    return {};
  }
}

// 验证邮箱
export async function verifyEmail(token) {
  try {
    return await request({
      url: `/auth/email/verify?token=${token}`,
      method: "get",
      data: {},
    });
  } catch (error) {
    return {};
  }
}

// 忘记密码，发送邮箱
export async function forgetPsw(data) {
  try {
    return await request({
      url: `/code/email`,
      method: "post",
      data: { ...data },
    });
  } catch (e) {
    return {};
  }
}

// 重置密码
export async function resetPsw(data) {
  try {
    return await request({
      url: `/auth/password/reset`,
      method: "post",
      data,
    });
  } catch (e) {
    return {};
  }
}

// 获取用户信息
export async function queryUserInfo() {
  try {
    return await request({
      url: `/user/info`,
      method: "get",
      data: {},
    });
  } catch (error) {
    return {};
  }
}

// 更新用户信息
export async function updateUserInfo(data = {}) {
  try {
    return await request({
      url: `/user`,
      method: "post",
      data,
    });
  } catch (error) {
    return {};
  }
}

// 获取常见问题列表
export async function queryFaq({ type, objectId, keyword }) {
  try {
    return await request({
      url: `/faq?type=${type}&objectId=${objectId}&keyword=${encodeURIComponent(
        keyword
      )}`,
      method: "get",
    });
  } catch (error) {
    return {};
  }
}

export async function query(data = {}) {
  try {
    return await request({
      url: "",
      method: "get",
      data,
    });
  } catch (error) {
    return {};
  }
}

export async function addShoppingCart(data = {}) {
  try {
    return await request({
      url: "/shopCart",
      method: "post",
      data,
    });
  } catch (error) {
    return {};
  }
}

export async function getShoppingCart() {
  try {
    return await request({
      url: "/shopCart",
      method: "get",
    });
  } catch (error) {
    return {};
  }
}

export async function deleteShoppingCart(data) {
  try {
    return await request({
      url: "/shopCart",
      method: "delete",
      data,
    });
  } catch (error) {
    return {};
  }
}

// 支付宝认证-初始化
export async function initAlipayCertification({ certName, certNo }) {
  try {
    return await request({
      url: `/certification/identity/alipay/init?certName=${certName}&certNo=${certNo}`,
      method: "get",
    });
  } catch (error) {
    return {};
  }
}

// 支付宝认证-查询结果
export async function queryCertResult() {
  try {
    return await request({
      url: `/certification/identity/check`,
      method: "get",
    });
  } catch (error) {
    return {};
  }
}

// 检测是否绑定了邮箱或手机号
export async function checkHavePhoneOrEmail() {
  try {
    return await request({
      url: `/certification/checkHavePhoneOrEmail`,
      method: "get",
    });
  } catch (error) {
    return {};
  }
}

// 发送验证码
export async function sendVerificationCode(phoneOrEmail) {
  try {
    return await request({
      url: `/code/sendVerificationCode?phoneOrEmail=${phoneOrEmail}`,
      method: "get",
    });
  } catch (error) {
    return {};
  }
}
// 绑定邮箱
export async function bandEmailOrPhone(phoneOrEmail, code, password) {
  try {
    return await request({
      url: `/code/bandEmailOrPhone`,
      method: "put",
      data: {
        phoneOrEmail: phoneOrEmail,
        code: code,
      },
    });
  } catch (error) {
    return {};
  }
}

// 实人认证-提交
export async function postCertification(data = {}) {
  try {
    return await request({
      url: `/certification/identity/post`,
      method: "post",
      data,
    });
  } catch (error) {
    return {};
  }
}

// 获取消息列表
export async function queryMessageList({ current, size, type }) {
  try {
    return await request({
      url: `/messagebox?size=${size}&current=${current}&type=${
        type ? type : ""
      }`,
      method: "get",
    });
  } catch (error) {
    return {};
  }
}

// 通过邮箱注册
export async function registerByEmail(data = {}) {
  try {
    return await request({
      url: `/code/registerByEmail`,
      method: "post",
      data,
    });
  } catch (error) {
    return {};
  }
}

// 微信登陆绑定邮箱
export async function bindingEmail(data = {}) {
  try {
    return await request({
      url: `/wechat/bindingEmail`,
      method: "post",
      data,
    });
  } catch (error) {
    return {};
  }
}
export async function checkEmail(email) {
  try {
    return await request({
      url: `/code/checkEmail?email=${email}`,
      method: "get",
    });
  } catch (error) {
    return {};
  }
}
export async function wechatBind() {
  try {
    return await request({
      url: `/wechat/bindWechat`,
      method: "get",
    });
  } catch (error) {
    return {};
  }
}

export async function wechatLogin() {
  try {
    return await request({
      url: `/wechat/wechatLogin`,
      method: "get",
    });
  } catch (error) {
    return {};
  }
}
export async function checkUsername(username) {
  try {
    return await request({
      url: `/code/checkUsername?username=${username}`,
      method: "get",
    });
  } catch (error) {
    return {};
  }
}
/**
 * 订单可用优惠券
 */
export async function getCouponsForOrder(classids) {
  try {
    return await request({
      url: `/userCoupon/getOrderCoupon?classIds=${classids}`,
      method: "get",
    });
  } catch (error) {
    return {};
  }
}

/**
 * 创建订单
 */
export async function createOrder(data) {
  try {
    return await request({
      url: `/order/create`,
      method: "post",
      data,
    });
  } catch (error) {
    return {};
  }
}

/**
 * 获取订单
 */
export async function getOrder(orderId) {
  try {
    return await request({
      url: `/order/${orderId}`,
      method: "get",
    });
  } catch (error) {
    return {};
  }
}

/**
 *
 * 检查是否已经拥有该课程了
 */
export async function checkUserCourse(data) {
  try {
    return await request({
      url: `/userCourse/checkUserCourse`,
      method: "post",
      data,
    });
  } catch (error) {
    return {};
  }
}

/**
 *
 * 当前班课是否有未支付的订单
 */
export async function isNotPay(data) {
  try {
    return await request({
      url: `/order/isNotPay`,
      method: "post",
      data,
    });
  } catch (error) {
    return {};
  }
}

/**
 * 订单支付
 * @param payType
 * @param orderId
 * @param data 分期期数，默认不传
 */
export function payOrder(payType, orderId, data) {
  try {
    let result = request({
      url: `/order/payment/${payType}/${orderId}`,
      method: "post",
      data: data,
      sync: true,
    });
    console.log("----------123123123", result);
    return result;
  } catch (error) {
    return {};
  }
}

export async function paymentCheck(orderId) {
  try {
    return await request({
      url: `/order/isSuccess/${orderId}`,
      method: "get",
    });
  } catch (error) {
    return {};
  }
}

export async function paymentPayPalCheck(orderId) {
  try {
    return await request({
      url: `/payment/callback/paypal?orderId=${orderId}`,
      method: "post",
    });
  } catch (error) {
    return {};
  }
}

/**
 * 查询用户所有订单
 */
export async function queryOrders(orderStatus, page, size) {
  try {
    return await request({
      url: `/order?orderStatus=${orderStatus}&current=${page}&size=${size}`,
      method: "get",
    });
  } catch (error) {
    return {};
  }
}

export async function getVideoUrl(videoUrl) {
  try {
    return await request({
      url: `/userCourse/getToken?videoUrl=${videoUrl}`,
      method: "get",
    });
  } catch (error) {
    return {};
  }
}

/**
 * 计算花呗分期
 */
export async function huabeiQuery(data) {
  try {
    return await request({
      url: `/order/calculate`,
      method: "post",
      data,
    });
  } catch (error) {
    return {};
  }
}

export async function getAllClasses() {
  try {
    return await request({
      url: `/class/all-course`,
      method: "get",
    });
  } catch (error) {
    return {};
  }
}

/**
 * 获取课程的套餐详情
 */
export async function getClass(id) {
  try {
    return await request({
      url: `/class/${id}`,
      method: "get",
    });
  } catch (error) {
    return {};
  }
}

/**
 * 获取课程的套餐详情
 */
export async function checkFirstBuy(comboId) {
  try {
    return await request({
      url: `/order/checkFirstBuy?comboId=${comboId}`,
      method: "get",
    });
  } catch (error) {
    return {};
  }
}
/**
 * 获取课程试听内容
 * */
export async function getClassListion(courseId) {
  try {
    return await request({
      url: `/class/${courseId}/trial`,
      method: "get",
    });
  } catch (error) {
    return {};
  }
}
/**
 * 获取组合套餐列表
 * */
export async function getCourseClassCombo(courseId) {
  try {
    return await request({
      url: `/class/courseClassCombo/${courseId}`,
      method: "get",
    });
  } catch (error) {
    return {};
  }
}
export async function getCourseBanner(id) {
  try {
    return await request({
      url: `/pictureOfLocation/pictureOfPosition?location=${id}`,
      method: "get",
    });
  } catch (error) {
    return {};
  }
}

export async function getCourseComments(courseId) {
  try {
    return await request({
      url: `/comment/course/${courseId}`,
      method: "get",
    });
  } catch (error) {
    return {};
  }
}
export async function getClassComments(classId, pageSize, currentPage) {
  try {
    return await request({
      url: `/comment/class/${classId}?current=${currentPage}&size=${pageSize}`,
      method: "get",
    });
  } catch (error) {
    return {};
  }
}
// 检查是否支付
export async function paypalCheck(orderId) {
  try {
    return await request({
      url: `/payment/callback/paypal`,
      method: "post",
      data: { orderId: orderId },
    });
  } catch (error) {
    return {};
  }
}
// 获取课程列表
export async function getCourseList(page, size) {
  try {
    return await request({
      url: `/userCourse/pageUserUserCourse?current=${page}&size=${size}`,
      method: "get",
    });
  } catch (error) {
    return {};
  }
}
// 开始课程
export async function startUserCourse(userId, courseId, startTime) {
  try {
    return await request({
      url: `/userCourse/startsUserCourse/${userId}/${courseId}`,
      method: "post",
      data: { startTime: startTime },
    });
  } catch (error) {
    return {};
  }
}
// 验证邮箱
export async function userVerify(key, invitationEmailCode) {
  try {
    return await request({
      url: `/code/activationEmail?key=${key}&invitationEmailCode=${invitationEmailCode}`,
      method: "get",
    });
  } catch (error) {
    return {};
  }
}

// 搜索常见问题
export async function searchQuestions(keyword, objectId) {
  try {
    return await request({
      url: `/faq?faqType=1&keyword=${keyword}&objectId=${objectId}`,
      method: "get",
    });
  } catch (error) {
    return {};
  }
}

export async function listAudition(courseId, keyword, modelId) {
  try {
    return await request({
      url: `/course/${courseId}/chapters?keyWord=${keyword}&modelId=${modelId}`,
      method: "get",
    });
  } catch (error) {
    return {};
  }
}

export async function startEnd(data) {
  try {
    return await request({
      url: `/section/end`,
      method: "post",
      data: data,
    });
  } catch (error) {
    return {};
  }
}
// 评论列表
export async function sectionList(sectionId) {
  try {
    return await request({
      url: `/comment/section/${sectionId}`,
      method: "get",
    });
  } catch (error) {
    return {};
  }
}
// 发布评论
export async function comment(data) {
  try {
    return await request({
      url: `/comment`,
      method: "post",
      data: data,
    });
  } catch (error) {
    return {};
  }
}
// 课程评价列表
export async function courseCommentList(sectionId) {
  try {
    // return await request({
    //   url: `/comment/course/${courseId}/${sectionId}`,
    //   method: "get",
    // });
    return await request({
      url: `/comment/course/${sectionId}`,
      method: "get",
    });
  } catch (error) {
    return {};
  }
}
// 获取班课规划列表
export async function coursePlanList(sectionId) {
  try {
    return await request({
      url: `/comment/model/${sectionId}`,
      method: "get",
    });
  } catch (error) {
    return {};
  }
}

// 获取规划
export async function getPlanning(courseId) {
  try {
    return await request({
      url: `/userCourse/getPlan/${courseId}`,
      method: "get",
    });
  } catch (error) {
    return {};
  }
}

// 获取展示的代码
export async function getCodeByUrl(url) {
  try {
    return await request({
      url: `/course/getCode?url=${url}`,
      method: "get",
    });
  } catch (error) {
    return {};
  }
}

// 获取最后一次观看的视频信息
export async function getLastWatchByGet(superType, courseId) {
  try {
    return await request({
      url: `/course/${superType}/${courseId}/getLastWatch`,
      method: "get",
    });
  } catch (error) {
    return {};
  }
}

// 获取websocket地址
export async function getWebSocketURL() {
  try {
    return await request({
      url: `/common/getWebSocketURL`,
      method: "get",
    });
  } catch (error) {
    return {};
  }
}
// 退团
export async function backGroup(groupBuyId) {
  try {
    return await request({
      url: `/active/groupBuy/backGroup/` + groupBuyId,
      method: "put",
    });
  } catch (error) {
    return {};
  }
}
// 确认团成员
export async function checkMember(comboId, activeId, groupId) {
  try {
    return await request({
      url: `/active/groupBuy/checkMember?comboId=${comboId}&activeId=${activeId}&groupId=${groupId}`,
      method: "get",
    });
  } catch (error) {
    return {};
  }
}

// 获取团信息
export async function getGroup(groupId) {
  try {
    return await request({
      url: `/active/groupBuy/getGroup?groupBuyId=${groupId}`,
      method: "get",
    });
  } catch (error) {
    return {};
  }
}

// 验证码登录

export async function verifyCodeLogin(data) {
  try {
    return await request({
      url: `/auth/verificationCodeLogin`,
      method: "post",
      data: data,
    });
  } catch (error) {
    return {};
  }
}
// 获取开课倒计时
export async function getCountDownClass(courseId) {
  try {
    return await request({
      url: `/smallClass/countdown/${courseId}`,
      method: "get",
    });
  } catch (error) {
    return {};
  }
}
// 检查是否设置密码
export async function getPasswordExist() {
  try {
    return await request({
      url: `/user/havePassword`,
      method: "get",
    });
  } catch (error) {
    return {};
  }
}
// 班课评价 个人中心

export async function getCommentsClassList() {
  try {
    return await request({
      url: `/comment/waitComment`,
      method: "get",
    });
  } catch (error) {
    return {};
  }
}

export async function publishComments(data) {
  try {
    return await request({
      url: `/comment`,
      method: "post",
      data,
    });
  } catch (error) {
    return {};
  }
}

export async function readNotices(data) {
  try {
    return await request({
      url: `/messagebox/read`,
      method: "put",
      data,
    });
  } catch (error) {
    return {};
  }
}
// 已读全部通知
export async function readAllNotices(data) {
  try {
    return await request({
      url: `/messagebox/readAll`,
      method: "put",
      data,
    });
  } catch (error) {
    return {};
  }
}
export async function shareCount(data) {
  try {
    return await request({
      url: `/active/overview`,
      method: "post",
      data,
    });
  } catch (error) {
    return {};
  }
}
// 提交问卷
export async function submitResearch(data) {
  try {
    return await request({
      url: `/student`,
      method: "post",
      data,
    });
  } catch (error) {
    return {};
  }
}
// 检查是否做了调查问卷
export async function getDoResearch() {
  try {
    return await request({
      url: `/student/check`,
      method: "get",
    });
  } catch (error) {
    return {};
  }
}
// 获取clientId
export async function getPaypalClientId() {
  try {
    return await request({
      url: `/order/getPayPalClientId`,
      method: "get",
    });
  } catch (error) {
    return {};
  }
}
// 检查用户是否买课程
export async function checkUserBuyCourse() {
  try {
    return await request({
      url: `/user/checkUserCourse`,
      method: "get",
    });
  } catch (error) {
    return {};
  }
}

// 获取优惠打折价格
export async function getCouponDiscountPrice(data) {
  try {
    return await request({
      url: `/order/getPromotionCodeAmount`,
      method: "post",
      data,
    });
  } catch (error) {
    return {};
  }
}
// 绑定邮箱
export async function bindEmail(data) {
  try {
    return await request({
      url: `/auth/bindingEmail`,
      method: "put",
      data,
    });
  } catch (error) {
    return {};
  }
}

// 获取用户状态
export async function getUserStatusData() {
  try {
    return await request({
      url: `/auth/isAuthenticationEmail`,
      method: "get",
    });
  } catch (error) {
    return {};
  }
}

// 获取用户信息
export async function getUserInfoData() {
  try {
    return await request({
      url: `/auth/getUserPhone`,
      method: "get",
    });
  } catch (error) {
    return {};
  }
}
// 修改/设置密码
export async function setPassword(data) {
  try {
    return await request({
      url: `/auth/updatePassword`,
      method: "post",
      data,
    });
  } catch (error) {
    return {};
  }
}

// 验证码验证
export async function checkSmsCode(data) {
  try {
    return await request({
      url: `/auth/checkSmsCode`,
      method: "post",
      data,
    });
  } catch (error) {
    return {};
  }
}

// 更换手机发送验证码
export async function sendUpdatePhoneSms() {
  try {
    return await request({
      url: `/code/sendUpdatePhoneSms`,
      method: "get",
    });
  } catch (error) {
    return {};
  }
}

// 验证旧手机验证码
export async function checkUserOldPhoneAndCode(data) {
  try {
    return await request({
      url: `/auth/checkUserOldPhoneAndCode`,
      method: "post",
      data
    });
  } catch (error) {
    return {};
  }
}

// 更新手机
export async function updatePhone(data) {
  try {
    return await request({
      url: `/code/updatePhone`,
      method: "put",
      data,
    });
  } catch (error) {
    return {};
  }
}
